import { ImageRatio } from '@components/ImageThumbnailRatio/ImageRatio';
import VnrLink from '@components/VnrLink';
import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import useTranslate from 'src/hook/useTranslate';

import styles from './Menu.module.scss';

const MenuDesktop = dynamic(() => import('./MenuDesktop'), {
  ssr: false,
});
// const MenuMobile = dynamic(() => import('./MenuMobile'), {
//   ssr: false,
// });

const Menu = ({ featurePostGroups, isBannerHidden }) => {
  const translate = useTranslate();
  const [topPosition, setTopPosition] = useState(isMobile ? '29vw + 25px' : '140px');

  useEffect(() => {
    if (isBannerHidden) {
      // Once the banner is hidden, move the menu to the top
      const stickyPosition = isMobile ? '70px' : '0px';
      setTopPosition(stickyPosition);
    }
  }, [isBannerHidden]);

  return (
    <div
      id="menuVnrebates"
      style={{ top: `${topPosition}`, position: 'sticky' }}
      className={styles.Menu}
    >
      <div className={styles.menuContent}>
        <div className={styles.logo}>
          <VnrLink to="/">
            <ImageRatio src="/icons/logoWhite.svg" alt="vnRebates" ratio="2905:800" />
          </VnrLink>
        </div>
        <MenuDesktop featurePostGroups={featurePostGroups} translate={translate} />
        {/* <div className={styles.mobileMenu}>
          <MenuMobile translate={translate} />
        </div> */}
      </div>
    </div>
  );
};
export default Menu;

import { handleAfterLoginComplete, processAuthResponse, useAuth } from '@components/Auth';
import { ImageThumbnailRatio } from '@components/ImageThumbnailRatio';
import ModalHook, { useModal } from '@components/ModalHook';
import { MODAL_ID as POPUP_USER_SENT_INFORMATION } from '@components/popup/PopupUserSentInformation';
import VnrLink from '@components/VnrLink';
import { createAnonymousUserData } from '@services/anonymousUserData.service';
import { updateUserProfile } from '@services/user.service';
import { triggerEventUserFillInformation } from '@utils/gtmUtil';
import { localStorageUtil } from '@utils/storageUtil';
import { validateEmail, validatePhoneNumber } from '@utils/validationRules';
import { Button, Checkbox, Col, Form, Image, Input, message, Modal, Row, Select, Skeleton } from 'antd';
import Router, { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ArrowFlip from './ArrowFlip';
import styles from './BackcomPopup.module.scss';
import to from 'await-to-js';

export const POPUP_ID = 'BACKCOM_POPUP';
const BackcomPopup = () => {
  const [, showModal] = useModal(POPUP_USER_SENT_INFORMATION);
  const { user } = useAuth();
  const [form] = Form.useForm();
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [isHide, setIsHide] = useState(false);
  const [modalData, , hideModal] = useModal(POPUP_ID);
  const { setFieldsValue, resetFields } = form;
  const brokers = useSelector(state => Object.values(state.entityBroker.ids));
  const brokersActive = brokers.filter((broker) => broker.active === true);
  
  const handleRegister = async () => {
    form.validateFields().then(async (values) => {
      const dataRequest = {
        newUserEmail: values.email,
        brokerCode: values.broker
      };
      const [e, res] = await to(createAnonymousUserData(dataRequest));
    if (e) {
      message.error(e.response.data.message);
      return;
    }
        resetFields();
      hideModal();
      showModal();
    })
  };
  useEffect(() => {
    if (modalData?.data?.email) {
      setFieldsValue({email: modalData?.data?.email })
    }
  }, [modalData]);

  return (
    <ModalHook
      title={
        <span  className={styles.title}>
          Dịch vụ Hoàn tiền/ Backcom
        </span>
      }
      footer={[
        <div key={1} 
        onClick={handleRegister}
        className={styles.buttonLogin}
        >
          Đăng kí tư vấn
        </div>,
      ]}
      id={POPUP_ID}
      onCancel={() => {
        resetFields();
        hideModal();
      }}
      width={480}
      wrapClassName={styles.backcomPopup}
    >
      <div className={styles.desc}>Để lại thông tin liên hệ của bạn, chúng tôi sẽ liên hệ tư vấn cho bạn sớm nhất có thể</div>
      <Form 
        form={form} 
        layout="vertical"
        className="create-update-form"
      >
          <Form.Item name="email" label="Email của bạn" rules={[{ required: true, message: 'Vui lòng điền email!' }]}>
            <Input className={styles.inputForm} />
          </Form.Item>

          <Form.Item
            className={styles['broker-selection']}
            name="broker" label="Sàn quan tâm"
            rules={[{ required: true, message: 'Vui lòng chọn broker!' }]}
          >
            <Select
              placeholder="Chọn broker ..."
              className={styles['select-broker']}
              showSearch
              optionFilterProp="value"
              defaultActiveFirstOption
              // filterOption={(input, option) =>
              //   option?.children?.name?.toLowerCase().indexOf(input.toLowerCase()) >= 0
              // }
            >
              {(brokersActive || []).map((broker) => (
                <Select.Option key={broker.id} value={broker.code}>
                  <div style={{display: 'inline-flex', gap: '12px', alignItems: 'center'}}>
{broker?.image && <Image preview={false} src={broker.image} width={57} alt={broker.name} />}
                  {broker.name}
                  </div>
                  
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
    </ModalHook>
  );
};

export default BackcomPopup;

export const rootCate = [
  {
    code: 'Dành cho NGƯỜI mới',
    name: {
      vi: 'Dành cho NGƯỜI mới',
      en: 'Dành cho NGƯỜI mới',
      cn: 'Dành cho NGƯỜI mới'
    },
    // url: '/',
    child: [
      {
        code: 'Nhận airdrop 20$-50$',
        name: {
          vi: 'Nhận airdrop 20$-50$',
          en: 'Nhận airdrop 20$-50$',
          cn: 'Nhận airdrop 20$-50$'
        },
        url: '/airdrop-tai-vnrebates'
      },
      
      {
        code: 'Lộ trình trở thành Pro Trader',
        name: {
          vi: 'Lộ trình trở thành Pro Trader',
          en: 'Lộ trình trở thành Pro Trader',
          cn: 'Lộ trình trở thành Pro Trader'
        },
        url: '/con-duong-tro-thanh-pro-trader'
      },
      {
        code: 'Khóa học đầu tư Forex cơ bản',
        name: {
          vi: 'Khóa học đầu tư Forex cơ bản',
          en: 'Khóa học đầu tư Forex cơ bản',
          cn: 'Khóa học đầu tư Forex cơ bản'
        },
        url: 'https://vnrebates.io/trading-academy/hoc-dau-tu-forex-co-ban'
      },
      {
        code: 'Khóa học Price Action',
        name: {
          vi: 'Khóa học Price Action',
          en: 'Khóa học Price Action',
          cn: 'Khóa học Price Action'
        },
        url: 'https://vnrebates.io/trading-academy/hoc-price-action-a-z'
      },
      {
        code: 'Lộ trình Pro Trader',
        name: {
          vi: 'Lộ trình Pro Trader',
          en: 'Lộ trình Pro Trader',
          cn: 'Lộ trình Pro Trader'
        },
        url: '/con-duong-tro-thanh-pro-trader',
      }
    ]
  },
  {
    code: 'Khoá học trading nâng cao',
    name: {
      vi: 'Khoá học trading nâng cao',
      en: 'Khoá học trading nâng cao',
      cn: 'Khoá học trading nâng cao'
    },
    // url: '/',
    child: [
      {
        code: 'Trading contest',
        name: {
          vi: 'Trading contest',
          en: 'Trading contest',
          cn: 'Trading contest'
        },
        url: 'Trading contest'
      },
      {
        code: 'Khóa 1 - VSA & Volume Master',
        name: {
          vi: 'Khóa 1 - VSA & Volume Master',
          en: 'Khóa 1 - VSA & Volume Master',
          cn: 'Khóa 1 - VSA & Volume Master'
        },
        url: 'https://vnrebates.io/trading-academy-video/khoa-hoc-price-action-volume-master'
      },
      
      {
        code: 'Khóa 2 - Wyckoff 2.0',
        name: {
          vi: 'Khóa 2 - Wyckoff 2.0',
          en: 'Khóa 2 - Wyckoff 2.0',
          cn: 'Khóa 2 - Wyckoff 2.0'
        },
        url: 'https://vnrebates.io/trading-academy-video/khoa-hoc-wyckoff-2'
      },
      {
        code: 'Khóa 3 - SMC tinh gọn',
        name: {
          vi: 'Khóa 3 - SMC tinh gọn',
          en: 'Khóa 3 - SMC tinh gọn',
          cn: 'Khóa 3 - SMC tinh gọn'
        },
        url: 'https://vnrebates.io/trading-academy-video/khoa-hoc-smc-tinh-gon'
      },
      {
        code: 'Khóa 4 - Supply & Demand 2.0',
        name: {
          vi: 'Khóa 4 - Supply & Demand 2.0',
          en: 'Khóa 4 - Supply & Demand 2.0',
          cn: 'Khóa 4 - Supply & Demand 2.0'
        },
        url: 'https://vnrebates.io/trading-academy-video/khoa-hoc-snd-2.0'
      },
      {
        code: 'Khóa 5 - ICT Concept',
        name: {
          vi: 'Khóa 5 - ICT Concept',
          en: 'Khóa 5 - ICT Concept',
          cn: 'Khóa 5 - ICT Concept'
        },
        url: '/trading-academy-video/ict-concept'
      }
    ]
  },
  {
    code: 'Flashcard trading nâng cao',
    name: {
      vi: 'Flashcard trading nâng cao',
      en: 'Flashcard trading nâng cao',
      cn: 'Flashcard trading nâng cao'
    },
    // url: '/chung-khoan',
    child: [
      {
        code: 'Flashcard 1: VSA & Volume Master',
        name: {
          vi: 'Flashcard 1 - VSA & Volume Master',
          en: 'Flashcard 1: VSA & Volume Master',
          cn: 'Flashcard 1: VSA & Volume Master'
        },
        url: 'https://vnrebates.store/products/vsa-volume-master',
        level: 2,
        col_position: 0,
      },
      {
        code: 'Flashcard 2 Wyckoff 2.0',
        name: {
          vi: 'Flashcard 2 - Wyckoff 2.0',
          en: 'Flashcard 2 Wyckoff 2.0',
          cn: 'Flashcard 2 Wyckoff 2.0'
        },
        url: 'https://vnrebates.store/products/wyckoff-2-0',
        level: 2,
        col_position: 0,
      },
      {
        code: 'Flashcard 3 SMC tinh gọn',
        name: {
          vi: 'Flashcard 3 - SMC tinh gọn',
          en: 'Flashcard 3 SMC tinh gọn',
          cn: 'Flashcard 3 SMC tinh gọn'
        },
        url: 'https://vnrebates.store/products/smc-tinh-gon',
        level: 2,
        col_position: 0,
      },
      {
        code: 'Flashcard  4 Supply & Demand 2.0',
        name: {
          vi: 'Flashcard  4 - Supply & Demand 2.0',
          en: 'Flashcard  4 Supply & Demand 2.0',
          cn: 'Flashcard  4 Supply & Demand 2.0'
        },
        url: 'https://vnrebates.store/products/supply-demand-2-0',
        level: 2,
        col_position: 0,
      },
      {
        code: 'Flashcard 5 ICT Concept',
        name: {
          vi: 'Flashcard 5 - ICT Concept',
          en: 'Flashcard 5 ICT Concept',
          cn: 'Flashcard 5 - ICT Concept'
        },
        url: 'https://vnrebates.store/products/ict-concept',
        level: 2,
        col_position: 0,
      },
    ]
  },
  {
    code: 'FOREX/ CRYPTO BROKER HOÀN PHÍ',
    name: {
      vi: <span>FOREX/ CRYPTO BROKER HOÀN PHÍ</span>,
      en: <span>FOREX/ CRYPTO BROKER HOÀN PHÍ</span>,
      cn: <span>FOREX/ CRYPTO BROKER HOÀN PHÍ</span>,
    },
    // url: '/hang-hoa',
    child: [
      {
        code: 'tool5',
        name: {
          vi: 'Forex broker',
          en: 'Forex broker',
          cn: 'Forex broker'
        },
        url: 'https://vnrebates.io/forex-broker',
        level: 2,
        col_position: 1,
      },
      {
        code: 'Crypto broker',
        name: {
          vi: 'Crypto broker',
          en: 'Crypto broker',
          cn: 'Crypto broker'
        },
        url: 'https://vnrebates.io/crypto-broker',
        level: 2,
        col_position: 1,
      },
    ]
  }
];

export const siteList = [
  {
    code: 'info',
    name: {
      vi: 'Bảo mật thông tin',
      en: 'Information Confidentiality',
      cn: '信息保密'
    },
    url: '/bao-mat-thong-tin'
  },
  {
    code: 'rule',
    name: {
      vi: 'Điều khoản sử dụng',
      en: 'Terms of Use',
      cn: '使用条款'
    },
    url: '/dieu-khoan-su-dung'
  },
  {
    code: 'risk',
    name: {
      vi: 'Miễn trừ trách nhiệm',
      en: 'Disclaimer',
      cn: '免责声明'
    },
    url: '/mien-tru-trach-nhiem'
  },
  {
    code: 'about',
    name: {
      vi: 'Về chúng tôi',
      en: 'About Us',
      cn: '关于我们'
    },
    url: '/ve-chung-toi'
  },
  {
    code: 'faq',
    name: {
      vi: 'FAQ',
      en: 'FAQ',
      cn: '常问问题'
    },
    url: '/faq'
  },
  {
    code: 'contact',
    name: {
      vi: 'Liên hệ',
      en: 'Contact',
      cn: '联系'
    },
    url: '/contact'
  }
];
import React from 'react';

const Telegram = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
      <g clipPath="url(#clip0_182_691)">
        <path
          d="M9.41702 15.179L9.02002 20.763C9.58802 20.763 9.83401 20.519 10.129 20.226L12.792 17.681L18.31 21.722C19.322 22.286 20.035 21.989 20.308 20.791L23.93 3.81896L23.931 3.81796C24.252 2.32196 23.39 1.73696 22.404 2.10396L1.11401 10.255C-0.338986 10.819 -0.316986 11.629 0.867014 11.996L6.31001 13.689L18.953 5.77796C19.548 5.38396 20.089 5.60196 19.644 5.99596L9.41702 15.179Z"
          fill="#0872CA"
        />
      </g>
      <defs>
        <clipPath id="clip0_182_691">
          <rect width="24" height="24" fill="white" transform="translate(0 -0.00195312)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Telegram;

import React from 'react';

const Facebook = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
      <g clipPath="url(#clip0_182_688)">
        <path
          d="M15.997 3.98305H18.188V0.167047C17.81 0.115047 16.51 -0.00195312 14.996 -0.00195312C11.837 -0.00195312 9.67305 1.98505 9.67305 5.63705V8.99805H6.18705V13.264H9.67305V23.998H13.947V13.265H17.292L17.823 8.99905H13.946V6.06005C13.947 4.82705 14.279 3.98305 15.997 3.98305Z"
          fill="#0872CA"
        />
      </g>
      <defs>
        <clipPath id="clip0_182_688">
          <rect width="24" height="24" fill="white" transform="translate(0 -0.00195312)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Facebook;

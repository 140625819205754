import React from 'react';

const Youtube = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
      <g id="youtube 1" clipPath="url(#clip0_182_694)">
        <path
          id="Vector"
          d="M23.469 5.92706L23.499 6.12306C23.209 5.09406 22.426 4.30006 21.431 4.01106L21.41 4.00606C19.539 3.49806 12.01 3.49806 12.01 3.49806C12.01 3.49806 4.5 3.48806 2.61 4.00606C1.596 4.30006 0.812004 5.09406 0.527004 6.10206L0.522004 6.12306C-0.176996 9.77406 -0.181996 14.1611 0.553004 18.0701L0.522004 17.8721C0.812004 18.9011 1.595 19.6951 2.59 19.9841L2.611 19.9891C4.48 20.4981 12.011 20.4981 12.011 20.4981C12.011 20.4981 19.52 20.4981 21.411 19.9891C22.426 19.6951 23.21 18.9011 23.495 17.8931L23.5 17.8721C23.818 16.1741 24 14.2201 24 12.2241C24 12.1511 24 12.0771 23.999 12.0031C24 11.9351 24 11.8541 24 11.7731C24 9.77606 23.818 7.82206 23.469 5.92706ZM9.608 15.6491V8.35606L15.874 12.0081L9.608 15.6491Z"
          fill="#0872CA"
        />
      </g>
      <defs>
        <clipPath id="clip0_182_694">
          <rect width="24" height="24" fill="white" transform="translate(0 -0.00195312)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Youtube;

import VnrLink from '@components/VnrLink';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { GTM_EVENTS } from 'src/constants';

import style from './BtnMessenger.module.scss';

const ButtonTelegram = ({ text, link = 'https://t.me/vnrebatessupport', width = 160 }) => {
  const [isFirstOUterCLick, setIsFirstOuterClick] = useState(false);
  const [show, setShow] = useState(false);
  const { locale } = useRouter();

  const supportRender = {
    vi: {
      content: 'Chat với Support',
      style: style.telegramGroupVisible,
      styleInvisible: style.telegramGroup,
      width: width,
      icon: (
        <svg
          width="24"
          height="20"
          viewBox="0 0 25 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.4124 13.181L10.0154 18.765C10.5834 18.765 10.8294 18.521 11.1244 18.228L13.7874 15.683L19.3054 19.724C20.3174 20.288 21.0304 19.991 21.3034 18.793L24.9254 1.82104L24.9264 1.82004C25.2474 0.324039 24.3854 -0.260961 23.3994 0.106039L2.10939 8.25704C0.656387 8.82104 0.678387 9.63104 1.86239 9.99804L7.30539 11.691L19.9484 3.78004C20.5434 3.38604 21.0844 3.60404 20.6394 3.99804L10.4124 13.181Z"
            fill="#ffffff"
          />
        </svg>
      ),
      styleWrapper: style.iconTelegram,
    },
    en: {
      content: <img width={100} height="auto" src="/images/whatsapp_qr.jpg" alt="whatsapp" />,
      style: style.appVisible,
      styleInvisible: style.appInvisible,
      width: 104,
      icon: (
        <svg
          viewBox="0 0 20 20"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          fill="#000000"
        >
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            {' '}
            <title>WhatsApp</title> <defs> </defs>{' '}
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              {' '}
              <g
                id="Dribbble-Light-Preview"
                transform="translate(-300.000000, -7599.000000)"
                fill="#ff7300"
              >
                {' '}
                <g id="icons" transform="translate(56.000000, 160.000000)">
                  {' '}
                  <path
                    d="M259.821,7453.12124 C259.58,7453.80344 258.622,7454.36761 257.858,7454.53266 C257.335,7454.64369 256.653,7454.73172 254.355,7453.77943 C251.774,7452.71011 248.19,7448.90097 248.19,7446.36621 C248.19,7445.07582 248.934,7443.57337 250.235,7443.57337 C250.861,7443.57337 250.999,7443.58538 251.205,7444.07952 C251.446,7444.6617 252.034,7446.09613 252.104,7446.24317 C252.393,7446.84635 251.81,7447.19946 251.387,7447.72462 C251.252,7447.88266 251.099,7448.05372 251.27,7448.3478 C251.44,7448.63589 252.028,7449.59418 252.892,7450.36341 C254.008,7451.35771 254.913,7451.6748 255.237,7451.80984 C255.478,7451.90987 255.766,7451.88687 255.942,7451.69881 C256.165,7451.45774 256.442,7451.05762 256.724,7450.6635 C256.923,7450.38141 257.176,7450.3464 257.441,7450.44643 C257.62,7450.50845 259.895,7451.56477 259.991,7451.73382 C260.062,7451.85686 260.062,7452.43903 259.821,7453.12124 M254.002,7439 L253.997,7439 L253.997,7439 C248.484,7439 244,7443.48535 244,7449 C244,7451.18666 244.705,7453.21526 245.904,7454.86076 L244.658,7458.57687 L248.501,7457.3485 C250.082,7458.39482 251.969,7459 254.002,7459 C259.515,7459 264,7454.51465 264,7449 C264,7443.48535 259.515,7439 254.002,7439"
                    id="whatsapp-[#128]"
                  >
                    {' '}
                  </path>{' '}
                </g>{' '}
              </g>{' '}
            </g>{' '}
          </g>
        </svg>
      ),
      styleWrapper: style.iconWhatsapp,
    },
    cn: {
      content: <img width={100} height="auto" src="/images/wechat_qr.jpg" alt="wechat" />,
      style: style.appVisible,
      styleInvisible: style.appInvisible,
      width: 104,
      icon: (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <title>WeChat</title>
          <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            {' '}
            <path
              d="M11.8088 7.05902C11.3508 7.06746 10.9637 7.45766 10.9718 7.90277C10.9802 8.36421 11.3599 8.7252 11.8309 8.7196C12.3031 8.71385 12.6613 8.3491 12.6568 7.87785C12.6529 7.41534 12.2749 7.05051 11.8088 7.05902ZM7.35079 7.91557C7.36789 7.47215 6.98366 7.07275 6.52729 7.05933C6.06002 7.04561 5.67572 7.40269 5.66207 7.8632C5.64827 8.32985 6.0052 8.70373 6.47584 8.71569C6.94241 8.72757 7.33354 8.36996 7.35079 7.91557ZM15.8953 8.67099C14.0388 8.76798 12.4244 9.33078 11.1137 10.6023C9.7894 11.887 9.18488 13.4611 9.35012 15.4126C8.62446 15.3226 7.96351 15.2237 7.2988 15.1678C7.06924 15.1484 6.7968 15.1759 6.60235 15.2856C5.95689 15.6498 5.33812 16.061 4.60471 16.5195C4.73928 15.9108 4.82638 15.3778 4.98058 14.8652C5.09398 14.4884 5.04146 14.2788 4.69434 14.0333C2.46568 12.4599 1.52624 10.1051 2.22929 7.68071C2.87973 5.43794 4.47705 4.07778 6.64744 3.36875C9.60982 2.4011 12.939 3.38815 14.7404 5.74012C15.391 6.58969 15.7899 7.54323 15.8953 8.67099Z"
              fill="#fff"
            ></path>{' '}
            <path
              d="M17.6884 12.3843C17.3253 12.3818 17.0167 12.6791 17.0019 13.046C16.9861 13.4383 17.2912 13.7605 17.6794 13.7615C18.055 13.7627 18.3517 13.4787 18.3655 13.105C18.38 12.7117 18.0749 12.387 17.6884 12.3843ZM13.3745 13.7662C13.7489 13.7666 14.057 13.4737 14.0711 13.1041C14.0861 12.7127 13.7713 12.3845 13.3795 12.3828C12.9915 12.381 12.6664 12.714 12.6799 13.0994C12.6927 13.4678 13.003 13.7658 13.3745 13.7662ZM20.0664 20.2452C19.4785 19.9835 18.9392 19.5907 18.3652 19.5308C17.7932 19.471 17.1919 19.801 16.5936 19.8622C14.771 20.0486 13.138 19.5407 11.7916 18.2956C9.23081 15.927 9.59671 12.2954 12.5594 10.3543C15.1925 8.62927 19.0542 9.20435 20.9107 11.598C22.5307 13.6866 22.3403 16.4592 20.3626 18.2139C19.7903 18.7217 19.5843 19.1397 19.9515 19.809C20.0193 19.9326 20.027 20.0891 20.0664 20.2452Z"
              fill="#fff"
            ></path>{' '}
          </g>
        </svg>
      ),
      styleWrapper: style.iconWechat,
    },
  };

  const onCheckClickBtnTelegram = (event) => {
    if (isFirstOUterCLick) {
      return;
    }

    const divTelegram = document.getElementById('buttonFloatTelegram');

    if (!divTelegram) return;

    if (divTelegram !== event.target && !divTelegram?.contains(event.target)) {
      setShow(false);
      setIsFirstOuterClick(true);
      return;
    }

    return;
  };

  useEffect(() => {
    if (isFirstOUterCLick) {
      return;
    }
    const setDelay = setTimeout(() => {
      setShow(true);

      window.addEventListener('click', function (event) {
        onCheckClickBtnTelegram(event);
      });
    }, 5000);

    return () => clearInterval(setDelay);
  }, []);

  return (
    <VnrLink
      data-gtm-event={GTM_EVENTS.BUTTON_HOMEPAGE_TELEGROUP}
      to={link}
      className="bannerDirectional"
    >
      <div id="buttonFloatTelegram" className={supportRender[locale].styleWrapper}>
        <div
          className={show ? supportRender[locale].style : supportRender[locale].styleInvisible}
          style={{ width: supportRender[locale].width }}
        >
          {text || supportRender[locale].content}
        </div>

        {supportRender[locale].icon}
      </div>
    </VnrLink>
  );
};

export default ButtonTelegram;

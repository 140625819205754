import React from 'react';

const Instagram = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
      <g id="instagram 1" clipPath="url(#clip0_182_700)">
        <path
          id="Vector"
          d="M12.004 5.83594C8.60101 5.83594 5.84601 8.59394 5.84601 11.9939C5.84601 15.3969 8.60401 18.1519 12.004 18.1519C15.407 18.1519 18.162 15.3939 18.162 11.9939C18.162 8.59094 15.404 5.83594 12.004 5.83594ZM12.004 15.9909C9.79501 15.9909 8.00701 14.2019 8.00701 11.9939C8.00701 9.78594 9.79601 7.99694 12.004 7.99694C14.212 7.99694 16.001 9.78594 16.001 11.9939C16.002 14.2019 14.213 15.9909 12.004 15.9909Z"
          fill="#0872CA"
        />
        <path
          id="Vector_2"
          d="M16.948 0.0739218C14.74 -0.0290782 9.271 -0.0240782 7.061 0.0739218C5.119 0.164922 3.406 0.633922 2.025 2.01492C-0.283002 4.32292 0.0119984 7.43292 0.0119984 11.9939C0.0119984 16.6619 -0.248002 19.6999 2.025 21.9729C4.342 24.2889 7.497 23.9859 12.004 23.9859C16.628 23.9859 18.224 23.9889 19.859 23.3559C22.082 22.4929 23.76 20.5059 23.924 16.9369C24.028 14.7279 24.022 9.25992 23.924 7.04992C23.726 2.83692 21.465 0.281922 16.948 0.0739218ZM20.443 20.4459C18.93 21.9589 16.831 21.8239 11.975 21.8239C6.975 21.8239 4.97 21.8979 3.507 20.4309C1.822 18.7539 2.127 16.0609 2.127 11.9779C2.127 6.45292 1.56 2.47392 7.105 2.18992C8.379 2.14492 8.754 2.12992 11.961 2.12992L12.006 2.15992C17.335 2.15992 21.516 1.60192 21.767 7.14592C21.824 8.41092 21.837 8.79092 21.837 11.9929C21.836 16.9349 21.93 18.9519 20.443 20.4459Z"
          fill="#0872CA"
        />
        <path
          id="Vector_3"
          d="M18.406 7.0318C19.2007 7.0318 19.845 6.38754 19.845 5.5928C19.845 4.79807 19.2007 4.15381 18.406 4.15381C17.6113 4.15381 16.967 4.79807 16.967 5.5928C16.967 6.38754 17.6113 7.0318 18.406 7.0318Z"
          fill="#0872CA"
        />
      </g>
      <defs>
        <clipPath id="clip0_182_700">
          <rect width="24" height="24" fill="white" transform="translate(0 -0.00195312)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Instagram;

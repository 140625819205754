// import IconDmca from '@components/Icons/iconDmca';
import { ImageThumbnailRatio } from '@components/ImageThumbnailRatio';
import VnrLink from '@components/VnrLink';
import React from 'react';
import LazyLoad from 'react-lazyload';

import styles from './Footer.module.scss';

const FooterCopyRight = ({translate}) => {
  return (
    <div className={styles.copyRight}>
      <div className={styles.copyRightContent}>
        <div className={styles.license}>
          <LazyLoad>
            <div className={styles.logoDmca}>
              <VnrLink 
                href="https://www.dmca.com/Protection/Status.aspx?ID=324db20d-919f-47d6-8fbe-ba69821b62da" 
                title="DMCA.com Protection Status" 
                class="dmca-badge"
                target="_blank"
              >
                <ImageThumbnailRatio
                  src ="https://images.dmca.com/Badges/_dmca_premi_badge_4.png?ID=324db20d-919f-47d6-8fbe-ba69821b62da"  
                  ratio='135:28'
                  alt="DMCA.com Protection Status" 
                />
              </VnrLink>  
              <script async src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"></script>
            </div>
          </LazyLoad>

          <div className={styles.licenseText}>
          Giấy phép đăng ký: Mạng xã hội thử nghiệm đang trong quá trình hoàn thành thủ tục cấp giấy phép
          </div>
        </div>     
        <div className={styles.copyRightReserve}>
          <div className={styles.copyRightText}>{translate.footer.copyRight} © 2021 &nbsp;</div>
          <div className={styles.copyRightVnr}> VNREBATES.IO{translate.footer.copyRightReserve}</div>
        </div>
      </div>

    </div>
  )
}
export default FooterCopyRight;
// import LogoVnrWhite from '@components/Icons/logoVnrWhite';
import { ImageThumbnailRatio } from '@components/ImageThumbnailRatio';
import VnrLink from '@components/VnrLink';
import { Col, Row } from 'antd';
import React from 'react';

// import { isMobile } from 'react-device-detect';
// import treeMenu from '../Menu/MenuData';
import styles from './Footer.module.scss';
import { rootCate, siteList } from './FooterData';

const FooterCategory = ({ translate, locale }) => {
  // const academyCate = treeMenu.find(item => item.code === "academy");

  const renderChildItem = (item) => {
    return (
      <div key={item.code} className={styles.childItem}>
        <span className={styles.dot}></span>
        <VnrLink to={item.url} className={styles.childItemName}>
          {item.name[locale]}
        </VnrLink>
      </div>
    );
  };

  return (
    <div className={styles.footerInformation}>
      <div className={styles.footerCategory}>
        {rootCate.map((item) => {
          return (
            <div key={item.code} className={styles.cateItem}>
              <h4>
                <VnrLink to={item.url} className={styles.title}>
                  {item.name[locale]}
                </VnrLink>
              </h4>

              <div className={styles.rootCate}>
                <div className={styles.cateItem}>
                  {item.child.map((childItem) => renderChildItem(childItem))}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className={styles.websiteSiteList}>
        {siteList.map((item) => {
          return (
            <div key={item.code} className={styles.siteListTitle}>
              <VnrLink to={item.url}>{item.name[locale]}</VnrLink>
            </div>
          );
        })}
      </div>

      <div className={styles.riskDisclaimer}>{translate.footer.riskDisclaimer}</div>
    </div>
  );
};
export default FooterCategory;

import React, { useState } from 'react';

import style from './BtnBackToTop.module.scss';

const ButonBackToTop = () => {
  const [isScrollDown, setIsScrollDown] = useState(true);

  if (typeof window !== "undefined") {
    
    window.onscroll = function() {
      if (this.oldScroll > this.scrollY) {
        setIsScrollDown(false);
      } else setIsScrollDown(true);
      this.oldScroll = this.scrollY;
    }
    
  }
  

  return (
    <div
      data-gtm-event="btn-back-to-top"
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      style={isScrollDown ? {visibility: "hidden"} : {}}
      className={style.ButonBackToTop}
    >
      <div className={style.Vector} />
      <div className={style.TOP}>TOP</div>
    </div>
  );
};

export default ButonBackToTop;

import { request } from '../utils/request';

export const getBannerAds = ({ name, type, code, status, imageUrl, url, startDate, endDate, page, size }) =>
  request({
    url: '/banner-ads',
    method: 'GET',
    params: {
      name,
      type, 
      code, 
      status, 
      imageUrl, 
      url, 
      startDate, 
      endDate, 
      page,
      size
    },
  });

export const getBannerShow = type =>
  request({
    url: '/banner-ads/getBannerShow',
    method: 'GET',
    params: {type: type},
  });

export const getHeaderBannerShow = ({codes}) =>
  request({
    url: '/banner-ads/getHeaderBannerShow',
    method: 'GET',
    params: {codes},
  });

export const getAllBannerHomeSlider = () =>
  request({
    url: '/banner-ads/getAllBannerHomeSlider',
    method: 'GET'
  });

export const deleteBannerAds = bannerAdsId =>
  request({
    url: `/banner-ads/${bannerAdsId}`,
    method: 'DELETE',
  });

export const createBannerAds = bannerAdsInfo =>
  request({
    url: '/banner-ads',
    method: 'POST',
    data: bannerAdsInfo,
  });

export const updateBannerAds = bannerAdsInfo =>
  request({
    url: '/banner-ads',
    method: 'PUT',
    data: bannerAdsInfo,
  });

export const getHistoriesChange = ({ id, page, size }) =>
  request({
    url: `/banner-ads/historiesChange/${id}`,
    method: 'GET',
    params: { page, size },
  });

import React from "react";

export const ImageRatio = ({
  ratio = "16:9",
  src = "",
  children = "",
}) => {
  const [width, height] = ratio.split(":");
  const percentage = (parseInt(height) / parseInt(width)) * 100;
  const style = {
    backgroundImage: `url('${src}')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    paddingTop: `${percentage}%`,
    marginTop: `${-percentage}%`,
    backgroundRepeat: "no-repeat",
  };
  return (
    <div style={{ paddingTop: `${percentage}%` }}>
      <div style={style}>
        {children}
      </div>
    </div>
  );
};

import { useAuth } from '@components/Auth';
import ButtonFloat from '@components/BtnFloat';
import { ImageThumbnailRatio } from '@components/ImageThumbnailRatio';
import { useModal } from '@components/ModalHook';
import BackcomPopup, { POPUP_ID } from '@components/ProfileUpdateInfo/BackcomPopup';
import VnrLink from '@components/VnrLink';
import { validateEmail } from '@utils/validationRules';
import { Input, message } from 'antd';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useTranslate from 'src/hook/useTranslate';

import styles from './Footer.module.scss';
import FooterCategory from './FooterCategory';
import FooterCopyRight from './FooterCopyRight';
import Facebook from './Icons/Facebook';
import Instagram from './Icons/Instagram';
import Telegram from './Icons/Telegram';
import Twitter from './Icons/Twitter';
import Youtube from './Icons/Youtube';

const Footer = () => {
  const { user } = useAuth();
  const translate = useTranslate();
  const { locale } = useRouter();
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [, showModal] = useModal(POPUP_ID);

  const callGetAllBrokers = async () => {
    await dispatch.entityBroker.getAllBrokerService();
  };

  const onChangeEmail = (e) => {
    setEmail(e?.target?.value || '');
  };

  const handleRegister = async () => {
    if (!validateEmail(email)) {
      message.error('Email không đúng!');
      return;
    }
    showModal({ email: email });
  };

  useEffect(() => {
    if (!user?.email) return;
    setEmail(user?.email);
  }, [user]);

  useEffect(() => {
    callGetAllBrokers();
  }, []);

  return (
    <div className={styles.footerContent}>
      <div className={styles.paddingContent}>
        {!user?.id && <div className={styles.serviceForm}>
          <div className={styles.serviceList}>
            <div className={styles.serviceRegister}>Đăng kí thành viên VNRebates để</div>
            <div className={styles.serviceWrapper}>
              <div className={styles.order}>
                <span>1</span>
              </div>
              <div className={styles.service}>Nhận Airdrop 20$-50$ tiền mặt</div>
            </div>
            <div className={styles.serviceWrapper}>
              <div className={styles.order}>
                <span>2</span>
              </div>

              <div className={styles.service}>Khoá học Trading miễn phí</div>
            </div>
          </div>

          <div className={styles.registerWrapper}>
            <Input
              value={email}
              onChange={onChangeEmail}
              placeholder="Nhập email của bạn..."
              className={styles.inputForm}
            />
            <div onClick={handleRegister} className={styles.buttonLogin}>
              Đăng ký
            </div>
          </div>
        </div>}

        <div className={styles.footer}>
          <div className={styles.socialSection}>
            <div className={styles.logo}>
              <ImageThumbnailRatio src="/icons/logoVnrHomePage.svg" ratio="204:56" />
            </div>
            <div className={styles.socialText}>
              Chúng tôi mong muốn tạo ra một Thế hệ nhà đầu tư/Traders có kiến thức nền tảng tốt và
              đầu tư một cách khoa học, thừa hưởng được những tinh hoa của Kiến thức đầu tư trên thế
              giới.
            </div>
            <div className={styles.advertising}>
              <div className={styles.advertisingText}>FOR ADVERTISING</div>
              <div className={styles.advertisingEmail}>partner@vnrebates.net</div>
            </div>
            <div>
              <div className={styles.advertisingText}>MẠNG XÃ HỘI</div>
              <div className={styles.iconList}>
                <div>
                  <VnrLink to="https://www.facebook.com/Vnrebates/">
                    <Facebook />
                  </VnrLink>
                </div>
                <div>
                  <VnrLink to="https://t.me/Vnrebates">
                    <Telegram />
                  </VnrLink>
                </div>
                <div>
                  <VnrLink to="https://www.youtube.com/channel/UCpCt75O_kcHwwkW5mgf45Mw">
                    <Youtube />
                  </VnrLink>
                </div>
                <div>
                  <VnrLink to="https://www.tiktok.com/@vnrebates.tradingstory">
                    <Twitter />
                  </VnrLink>
                </div>
                <div>
                  <VnrLink to="https://www.instagram.com/vnrebates/">
                    <Instagram />
                  </VnrLink>
                </div>
              </div>
            </div>
          </div>

          <FooterCategory translate={translate} locale={locale} />
        </div>
      </div>
      <FooterCopyRight translate={translate} />
      <ButtonFloat translate={translate} />
      <BackcomPopup />
    </div>
  );
};
export default Footer;

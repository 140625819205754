import React from 'react';

const Twitter = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
      <g id="twitter 1" clipPath="url(#clip0_182_697)">
        <path
          id="Vector"
          d="M21.534 7.11105C22.51 6.41805 23.331 5.55305 24 4.55705V4.55605C23.107 4.94705 22.157 5.20705 21.165 5.33305C22.185 4.72405 22.964 3.76705 23.33 2.61405C22.379 3.18105 21.329 3.58105 20.21 3.80505C19.307 2.84305 18.02 2.24805 16.616 2.24805C13.892 2.24805 11.699 4.45905 11.699 7.16905C11.699 7.55905 11.732 7.93405 11.813 8.29105C7.723 8.09105 4.103 6.13105 1.671 3.14405C1.247 3.88105 0.997 4.72405 0.997 5.63105C0.997 7.33505 1.874 8.84505 3.183 9.72005C2.392 9.70505 1.617 9.47505 0.96 9.11405V9.16805C0.96 11.559 2.665 13.545 4.902 14.003C4.501 14.113 4.065 14.165 3.612 14.165C3.297 14.165 2.979 14.147 2.681 14.081C3.318 16.029 5.128 17.462 7.278 17.509C5.604 18.818 3.478 19.607 1.177 19.607C0.774 19.607 0.387 19.589 0 19.54C2.18 20.945 4.762 21.748 7.548 21.748C16.231 21.748 21.89 14.504 21.534 7.11105Z"
          fill="#0872CA"
        />
      </g>
      <defs>
        <clipPath id="clip0_182_697">
          <rect width="24" height="24" fill="white" transform="translate(0 -0.00195312)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Twitter;

import React from 'react';

import ButtonBackToTop from './BtnBackToTop';
import style from './BtnFloat.module.scss';
import ButtonTelegram from './ButtonTelegram';

const ButtonFloat = ({text, link, width}) => {
  // const dispatchApp = useDispatch().app;
  // const [isLive, setIsLive] = useState(false);
  // const [videoId, setVideoId] = useState();

  // const [isCloseByUser, setIsCloseByUser] = useState(() => {
  //   if (typeof window === "undefined") {
  //     return false;
  //   };
  //   try {
  //     // Get from session storage
  //     const item = window.sessionStorage.getItem("isClosedLivestream");
      
  //     return item;
  //   } catch (error) {
  //     // If error also return initialValue
  //     console.log(error);
  //     return false;
  //   }
  // });

  // const handleCloseLiveStream = () => {
  //   setIsCloseByUser(true);
  //   window.sessionStorage.setItem("isClosedLivestream", true);
  // };

  // const handleGetLiveStreamStatus = async () => {
  //   const [, res] = await to(getLivestreamId());

  //   if (res?.data && res?.data !== '') {
  //     setIsLive(true);
  //     setVideoId(res?.data);
  //     dispatchApp.setLivestreamVideoId(res?.data);
  //   }
  // };

  // useEffect(() => {
  //   handleGetLiveStreamStatus();
  //   return;
  // }, []);

  // if (isLive && isMobile) {
  //   return (
  //     <div id="buttonFloat" className={style.ButtonFloatLive}>
  //       <ButtonBackToTop />
  //       <VnrLink to={`https://www.youtube.com/watch?v=${videoId}`} className={style.wrapper}>
  //         <div  className={style.logo}>
  //           <Logo />
  //         </div>
  //         <div className={style.logoCircle}>
  //         </div>
  //         <div className={style.circle}>
  //           <div className={style.liveButton}>
  //             LIVE
  //           </div>
  //         </div>
  //       </VnrLink>
  //       <ButtonTelegram
  //         text={text}
  //         link={link}
  //         width={width}
  //       />
  //     </div>
  //   );
  // };

  // if (isLive && !isCloseByUser && !isYoutubeOnViewPort) {
  //   return (
  //     <div id="buttonFloat" className={style.liveStreamPlayer}>
  //       <iframe 
  //         width="320" 
  //         height="180" 
  //         src="https://www.youtube.com/embed/live_stream?channel=UCpCt75O_kcHwwkW5mgf45Mw&autoplay=1&mute=1" 
  //         title="VnRebates Livestream" 
  //         frameborder="0" 
  //         allow="autoplay; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
  //         allowfullscreen
  //         enablejsapi="true"
  //       ></iframe>
  //       <div className={style.closeButton} onClick={handleCloseLiveStream}>
  //         <IconCloseX />
  //       </div>
  //     </div>
  //   )
  // };

  return (
    <div id="buttonFloat" className={style.ButtonFloat}>
      <ButtonBackToTop />
      <ButtonTelegram
        text={text}
        link={link}
        width={width}
      />
    </div>
  );
};

export default ButtonFloat;
